<template>
  <div :class="$vuetify.breakpoint.smAndUp ? 'w3-padding-64' : 'w3-padding-16'">
    <v-main>
      <v-snackbar top v-model="snackbar" :timeout="8000" :color="color" class="text-center mt-3">
        <span class="white--text">{{ message }}</span>
        <template v-slot:action="{ attrs }">
          <v-btn v-bind="attrs" @click="snackbar = !snackbar" text class="white--text">
            <v-icon class="text-white">mdi-close</v-icon>
          </v-btn>
        </template>

      </v-snackbar>
      <v-dialog transition="dialog-bottom-transition" v-model="oneApp" persistent max-width="350px">
        <v-card>
          <v-card-title style="border-bottom: 1px solid #f5f5f5">
            <div class="d-flex flex-row justify-space-between">
              <div>
                <span class="topic1"
                  style="color: #AB005E!important; font-size: 14px!important; font-weight: 600!important;">
                  Login to your 1app account to use your wallet</span>
              </div>

              <div>
                <v-btn icon right
                  @click="oneApp = false; loginLoader = false; loader = false; otp = ''; loginStatus = false">
                  <v-icon>mdi-close</v-icon>
                </v-btn>
              </div>
            </div>


          </v-card-title>
          <v-card-text class=" py-2">
            <v-form v-if="!(loginStatus)" ref="login">
              <label>Email / Phone Number</label>
              <v-text-field solo flat class="topic mb-n2" style="font-size:14px!important"
                :rules="[rules.required, rules.emailFormat]" v-model="email" type="email" outlined dense
                color="#e70a80"></v-text-field>

              <label>Password</label>
              <v-text-field solo flat class="topic mb-n2" style="font-size:14px!important" v-model="password" outlined
                dense color="#e70a80" :rules="[rules.required, rules.passwordFormat]"
                :type="visible ? 'text' : 'password'" :append-icon="visible ? 'mdi-eye-off' : 'mdi-eye'"
                @click:append="visible = !visible"></v-text-field>

            </v-form>
            <v-form v-else ref="otp" class="text-center">
              <label>Enter Confirmation OTP</label>
              <div class="mx-auto" style="max-width: 200px; ">
                <v-otp-input length="4" plain type="password" v-model="otp">

                </v-otp-input>
              </div>
              <br>

            </v-form>
          </v-card-text>
          <v-card-actions class="justify-center pt-2 pb-5">
            <div v-if="!(loginStatus)" style="width: 100%!important;">
              <v-btn text :loading="loginLoader" class="payButton" block @click="loginUser">Verify</v-btn>
            </div>

            <div v-else class="text-center" style="width: 100%!important;">
              <v-btn text :loading="loginLoader" class="payButton" block @click="verifyOtp">proceed</v-btn>
              <v-btn text color="#ab005e" @click="loginUser" :loading="resendLoader">
                <span class="text-overline" style="color:#ab005e!important;">Resend OTP</span>
              </v-btn>
              <br>
              <span class="topic1" style="font-size: 12px!important">Kindly check your phone/email for a confirmation
                OTP</span>
            </div>

          </v-card-actions>
        </v-card>

      </v-dialog>
      <v-img>

        <div class="" v-if="pageLoader || getCheckOutDetails === ''" style="margin: 0 auto; max-width: 450px">
          <div class="text-center w3-center justify-center" style="margin: 0 auto!important; ">

            <v-progress-circular color="#ab005e" indeterminate size="40" class="mx-auto "
              style="margin-top: 30vh!important;"></v-progress-circular>

          </div>
        </div>
        <v-row v-else class="px-3">
          <v-col cols="12" md="3" lg="4" sm="2" class="w3-hide-small">
            &nbsp;
          </v-col>
          <v-col cols="12" md="6" lg="4" sm="8">

            <div class="w3-hide-medium w3-hide-large">
              <div v-if="!paymentOption">
                <div class="d-flex flex-row">
                  <div>
                    <span>Pay with </span>
                    <b>{{ option !== 'rave' ? option : 'flutterwave' }}</b>
                  </div>
                  <v-spacer></v-spacer>
                  <div class="mt-n1">
                    <v-btn text class="pa-0" @click="paymentOption = !paymentOption" color="#ab005e"
                      style="font-weight: 700!important;">
                      <v-icon left small>mdi-swap-horizontal</v-icon>
                      <span class="text-lowercase" style="font-size: 14px;">change</span>
                    </v-btn>
                  </div>
                </div>
              </div>

              <div v-else style="margin-bottom: 5em!important;">
                <v-list dense color="#f4f4f4" flat transition="fade-transition">
                  <v-list-item>
                    <v-list-item-title class="py-3" style="line-height: 1.7!important;font-size:16px!important;">
                      <b class="text-uppercase" style="color:#ab005e ">1app Checkout</b>
                      <div>
                        Use one of the following payment methods to <br> pay
                        <b>{{ getCheckOutDetails.currency }} {{ formatCurrency(getCheckOutDetails.amount) }}</b>
                        to <b>{{ getCheckOutDetails.bname }}</b>.
                      </div>
                    </v-list-item-title>
                  </v-list-item>
                  <v-list-item link
                    @click="paymentOption = !paymentOption; option = item.value; currentPayment = item.key; modeChanger(item)"
                    v-for="item in gateway" style="    border-top: 1px solid #e6e6e6;" class="py-2" :keys="item.value">
                    <v-list-item-title style="width: 100%!important; font-size:16px!important;">
                      <span>Pay with</span> <b> {{ item.value !== 'rave' ? item.value : 'flutterwave' }}</b>
                    </v-list-item-title>
                  </v-list-item>
                </v-list>
              </div>
            </div>
            <div class="text-right w3-hide-small">

              <v-tooltip right open-delay="2">
                <template v-slot:activator="{ on, attr }">
                  <v-btn style="z-index: 9999!important;" color="white" @click="cancelPayment" v-on="on" v-bind="attr"
                    icon small>
                    <v-icon>mdi-close</v-icon>
                  </v-btn>

                </template>
                <span style="font-size: 12px; ">cancel payment</span>
              </v-tooltip>

            </div>
            <v-card flat v-if="!paymentOption" class="cards rounded w3-round-medium my-2">
              <v-toolbar flat color="white" class="px-2 py-4 elevation-1 w3-border-bottom w3-border-light-gray"
                style=" box-shadow: 0px 6px 25px rgb(0 0 0 / 7%)!important;">

                <div class="d-flex flex-row mb-2" style="width: 100%!important;">
                  <v-avatar size="45" class="elevation-1 mt-n1 mb-1">
                    <v-img :src="getCheckOutDetails.logo"></v-img>

                  </v-avatar>
                  <div class="ml-3 mt-2 d-none">
                    <span class="sectionHeaders text-capitalize" style="font-size: 11px!important;">{{
                      getCheckOutDetails.bname }}</span>

                  </div>
                  <v-spacer></v-spacer>

                  <div class="topic1 text-right w3-hide-small">
                    You are about to pay
                    <b>{{ getCheckOutDetails.currency }} {{ formatCurrency(amountToPay || getCheckOutDetails.amount)
                    }}</b>
                    to <b>{{ getCheckOutDetails.bname }}</b>.
                    <br>
                    Choose your payment option to proceed.
                  </div>
                  <div class="topic1 text-right w3-hide-large   w3-hide-medium">
                    <b>{{ getCheckOutDetails.bname }}</b>
                    <br>
                    pay
                    <b> {{ getCheckOutDetails.currency }} {{ formatCurrency(amountToPay || getCheckOutDetails.amount)
                    }}</b>
                  </div>
                </div>

              </v-toolbar>
              <v-tabs :active-class="'active-tab'" slider-color="#ab005e" vertical class="w3-round-medium"
                background-color="#f4f4f4" style=" background: #f4f4f4!important; min-height: 300px!important;"
                v-model="currentPayment" @change="tester($event)">
                <v-tab @change="modeChanger(item)" v-for="item in gateway" :key="item.value" active-class="active-tab"
                  class="py-7 w3-hide-small" style="border-top:1px solid rgba(189, 189, 189,0.2)!important;"
                  :style="{ color: 'black', fontWeight: '500' }">
                  <div class="d-flex flex-row  " style="width:100%!important;">

                    <img class="ml-1 mt-0" v-if="!!item.logo" style="max-height: 30px;" :src="item.logo">
                    <span v-else class="text-capitalize" style="font-size:12px!important;  "> <v-icon small
                        color="black">mdi-bank</v-icon> {{ item.title }}</span>
                  </div>
                </v-tab>
                <v-tab-item v-for="item in gateway" :key="item.key">
                  <div v-if="getCheckOutDetails.islive === 'test'" class="text-center pa-0"
                    style="border-top:1px solid #eb4d4d !important">
                    <v-btn class="white--text mt-n2" x-small elevation="0" color="#eb4d4d"
                      style="border-radius: 0 0 5px 5px !important;">
                      Test Mode
                    </v-btn>
                  </div>
                  <div v-if="item.value === '1app'">
                    <v-card flat>
                      <v-card-text class=" py-5">
                        <v-row>
                          <v-col cols="12" md="1" sm="1" lg="1">
                            &nbsp;
                          </v-col>
                          <v-col cols="12" md="10" sm="10" lg="10">
                            <p class="topic1 text-center mb-n2" style="font-size:14px!important">
                              {{ userinfo || 'Login to your 1app account to use your wallet' }}
                            </p>

                            <div v-if="!(loginStatus)">
                              <v-form ref="loginUser">
                                <label>Email / Phone Number</label>
                                <v-text-field solo flat class="topic mb-n2" style="font-size:14px!important"
                                  :rules="[rules.required]" v-model="email" type="text" outlined dense
                                  color="#e70a80"></v-text-field>

                                <label>Password</label>
                                <v-text-field solo flat class="topic mb-n2" style="font-size:14px!important"
                                  v-model="password" outlined dense color="#e70a80" :rules="[rules.required]"
                                  :type="visible ? 'text' : 'password'" :append-icon="visible ? 'mdi-eye-off' : 'mdi-eye'"
                                  @click:append="visible = !visible"></v-text-field>

                              </v-form>
                            </div>
                            <div v-else>
                              <v-form ref="otp" class="text-center">
                                <label>Enter Confirmation OTP</label>
                                <div class="mx-auto" style="max-width: 200px; ">
                                  <v-otp-input length="4" plain type="password" v-model="otp" @finish="verifyOtp">

                                  </v-otp-input>
                                </div>
                                <br>

                              </v-form>
                            </div>

                            <br>

                            <div>
                              <div v-if="!(loginStatus)" style="width: 100%!important;">
                                <v-btn text :loading="loginLoader" class="payButton" block @click="loginUser">
                                  <span class="text-capitalize">Verify</span></v-btn>
                              </div>

                              <div v-else class="text-center" style="width: 100%!important;">
                                <v-btn text :loading="loginLoader" class="payButton" block @click="verifyOtp"><span
                                    class="">Pay {{ getCheckOutDetails.currency }}{{ formatCurrency(amountToPay ||
                                      getCheckOutDetails.amount) }}</span></v-btn>
                                <v-btn text color="#ab005e" class="mt-1" @click="loginUser">
                                  <span class="text-overline" style="color:#ab005e!important;">Resend OTP</span>
                                </v-btn>
                                <br>
                                <span class="topic1" style="font-size: 12px!important">Kindly check your phone/email for a
                                  confirmation OTP</span>
                              </div>
                            </div>
                            &nbsp;


                          </v-col>

                        </v-row>

                      </v-card-text>

                    </v-card>
                  </div>
                  <div v-if="item.value === 'transfer'">
                    <v-row dense class="mt-2">
                      <v-col cols="12" md="1" lg="1" sm="1">
                        &nbsp;
                      </v-col>
                      <v-col cols="12" md="10" lg="10" sm="10">
                        <v-card class=" " flat style="line-height: 2.0">
                          <v-card-text class="py-4">
                            <div v-if="getIntializeTransfer === ''" style="margin-top:60px">
                              <div class="text-center w3-center justify-center" style="margin: 0 auto!important; ">

                                <v-progress-circular width="3" size="30" indeterminate
                                  color="#AB005E"></v-progress-circular>

                              </div>
                            </div>
                            <div v-else>
                              <p class="text-center topic1" style="font-size:14px!important">
                                Kindly Transfer
                                <b>{{ getCheckOutDetails.currency }} {{ formatCurrency(amountToPay ||
                                  getIntializeTransfer.amount) }}</b>
                                to the account below to pay to {{ getIntializeTransfer.busname }}
                              </p>
                              <div class=" text-center w3-center"
                                style="width:100%!important;     padding: 25px 0!important;  ;    background: #f5f5f5!important;">
                                <div v-if="getTransferTime > 0">
                                  <p class="topic1" style=" color: #011b33;!important; font-weight: 500!important;">
                                    <span style="font-size:17px!important;">{{ getIntializeTransfer.bank_name }}</span>
                                  </p>
                                  <p class="topic1 mb-0" style="color:#011b33!important; font-weight: 500!important;">

                                    <span class="sectionHeaders"
                                      style="font-size:18px!important; color:#011b33!important">{{
                                        getIntializeTransfer.accountno }}</span>
                                    <v-btn class="mt-n1" small icon color="#011b33"
                                      @click="copy(getIntializeTransfer.accountno)">
                                      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" role="img"
                                        aria-hidden="true" class="v-icon__svg">
                                        <path
                                          d="M19,21H8V7H19M19,5H8A2,2 0 0,0 6,7V21A2,2 0 0,0 8,23H19A2,2 0 0,0 21,21V7A2,2 0 0,0 19,5M16,1H4A2,2 0 0,0 2,3V17H4V3H16V1Z">
                                        </path>
                                      </svg>
                                    </v-btn>
                                  </p>
                                </div>
                                <div v-else>
                                  <p class="topic1" style=" color: #011b33;!important; font-weight: 500!important;">
                                    <span style="font-size:17px!important;">Account number has expired</span>
                                  </p>
                                  <v-btn plain @click="initializeTransfer" :loading="initializeLoader">
                                    <v-icon color="#011b33" left>mdi-restart</v-icon>
                                    <span class="sectionHeaders"
                                      style="font-size:13px!important; color:#011b33!important">Try again</span>

                                  </v-btn>
                                </div>



                              </div>
                              <div class="text-center mt-2" v-if="getTransferTime > 0">
                                <span style="color:#9c9c9c; " class="text-caption">Use this account for this transaction
                                  only</span>
                                <br>
                                <p class="topic1" style=" color:#ab005e!important;">Account expires in
                                  <b>{{ timeCalculator[1] }}mins : {{ timeCalculator[2] }} secs</b>
                                </p>
                              </div>
                              <v-btn :loading="loader" class="payButton" block elevation="2" @click="verifyTransfer">
                                <span class=" text-capitalize">I've sent the money</span>
                                <v-icon right>
                                  mdi-chevron-right
                                </v-icon>
                              </v-btn>
                            </div>



                          </v-card-text>
                        </v-card>

                      </v-col>
                    </v-row>
                  </div>
                  <div v-if="item.value === 'paystack'">
                    <v-row dense class="mt-2">
                      <v-col cols="12" md="2" lg="2" sm="2">
                        &nbsp;
                      </v-col>
                      <v-col cols="12" md="8" lg="8" sm="8">
                        <v-card class=" " flat style="line-height: 2.0">
                          <v-card-text class="py-8 text-center">
                            <br>
                            <p class="text-center topic1" style="font-size:15px!important">
                              Pay with paystack

                            </p>
                            <br>

                            <v-btn :loading="loader" class="payButton" elevation="0" @click="initializePayment">
                              <span class=" text-capitalize" style="font-size: 12px">Click here to continue</span>
                              <v-icon right>
                                mdi-chevron-right
                              </v-icon>
                            </v-btn>



                          </v-card-text>
                        </v-card>

                      </v-col>
                    </v-row>
                  </div>
                  <div v-if="item.value === 'interswitch'">
                    <v-row dense class="mt-2">
                      <v-col cols="12" md="2" lg="2" sm="2">
                        &nbsp;
                      </v-col>
                      <v-col cols="12" md="8" lg="8" sm="8">
                        <v-card class=" " flat style="line-height: 2.0">
                          <v-card-text class="py-8 text-center">
                            <br>
                            <p class="text-center topic1" style="font-size:15px!important">
                              Pay with Quickteller

                            </p>
                            <br>

                            <v-btn :loading="loader" class="payButton" elevation="0" @click="initializePayment">
                              <span class=" text-capitalize" style="font-size: 12px">Click here to continue</span>
                              <v-icon right>
                                mdi-chevron-right
                              </v-icon>
                            </v-btn>





                          </v-card-text>
                        </v-card>

                      </v-col>
                    </v-row>
                  </div>
                  <div v-if="item.value === 'rave'">
                    <v-row dense class="mt-2">
                      <v-col cols="12" md="2" lg="2" sm="2">
                        &nbsp;
                      </v-col>
                      <v-col cols="12" md="8" lg="8" sm="8">
                        <v-card class=" " flat style="line-height: 2.0">
                          <v-card-text class="py-8 text-center">
                            <br>
                            <p class="text-center topic1" style="font-size:15px!important">
                              Pay with Flutterwave

                            </p>
                            <br>

                            <v-btn :loading="loader" class="payButton" elevation="0" @click="initializePayment">
                              <span class=" text-capitalize" style="font-size: 12px">Click here to continue</span>
                              <v-icon right>
                                mdi-chevron-right
                              </v-icon>
                            </v-btn>



                          </v-card-text>
                        </v-card>

                      </v-col>
                    </v-row>
                  </div>
                  <div v-if="item.value === 'paypal'">
                    <v-row dense class="mt-2">
                      <v-col cols="12" md="2" lg="2" sm="2">
                        &nbsp;
                      </v-col>
                      <v-col cols="12" md="8" lg="8" sm="8">
                        <v-card class=" " flat style="line-height: 2.0">
                          <v-card-text class="py-8 text-center">
                            <br>
                            <p class="text-center topic1" style="font-size:15px!important">
                              PayPal

                            </p>
                            <!--                              <img style="height:120px" src="@/assets/img/coming-soon.png">-->
                            <span>You are currently not eligible for this checkout </span><br>
                            <span>Kindly contact us <a href="mailto:business@1appgo.com">business@1appgo.com</a> </span>




                          </v-card-text>
                        </v-card>

                      </v-col>
                    </v-row>
                  </div>
                  <div v-if="item.value === 'stripe'">
                    <v-row dense class="mt-2">
                      <v-col cols="12" md="2" lg="2" sm="2">
                        &nbsp;
                      </v-col>
                      <v-col cols="12" md="8" lg="8" sm="8">
                        <v-card class=" " flat style="line-height: 2.0">
                          <v-card-text class="py-8 text-center">
                            <br>
                            <p class="text-center topic1" style="font-size:15px!important">
                              Pay with Stripe

                            </p>
                            <br>

                            <v-btn :loading="loader" class="payButton" elevation="0" @click="initializePayment">
                              <span class=" text-capitalize" style="font-size: 12px">Click here to continue</span>
                              <v-icon right>
                                mdi-chevron-right
                              </v-icon>
                            </v-btn>





                          </v-card-text>
                        </v-card>

                      </v-col>
                    </v-row>
                  </div>
                  <div v-if="item.value === 'squad'">
                    <v-row dense class="mt-2">
                      <v-col cols="12" md="2" lg="2" sm="2">
                        &nbsp;
                      </v-col>
                      <v-col cols="12" md="8" lg="8" sm="8">
                        <v-card class=" " flat style="line-height: 2.0">
                          <v-card-text class="py-8 text-center">
                            <br>
                            <p class="text-center topic1" style="font-size:15px!important">
                              Pay with Squad

                            </p>
                            <br>

                            <v-btn :loading="loader" class="payButton" elevation="0" @click="initializePayment">
                              <span class=" text-capitalize" style="font-size: 12px">Click here to continue</span>
                              <v-icon right>
                                mdi-chevron-right
                              </v-icon>
                            </v-btn>





                          </v-card-text>
                        </v-card>

                      </v-col>
                    </v-row>
                  </div>
                  <div v-if="item.value === 'monnify'">
                    <v-row dense class="mt-2">
                      <v-col cols="12" md="2" lg="2" sm="2">
                        &nbsp;
                      </v-col>
                      <v-col cols="12" md="8" lg="8" sm="8">
                        <v-card class=" " flat style="line-height: 2.0">
                          <v-card-text class="py-8 text-center">
                            <br>
                            <p class="text-center topic1" style="font-size:15px!important">
                              Pay with Monnify

                            </p>
                            <br>

                            <v-btn :loading="loader" class="payButton" elevation="0" @click="initializePayment">
                              <span class=" text-capitalize" style="font-size: 12px">Click here to continue</span>
                              <v-icon right>
                                mdi-chevron-right
                              </v-icon>
                            </v-btn>





                          </v-card-text>
                        </v-card>

                      </v-col>
                    </v-row>
                  </div>
                </v-tab-item>


              </v-tabs>
            </v-card>
            <div class="w3-hide-medium w3-hide-large mt-5 mb-6">
              <div class="d-flex flex-column  justify-center ">
                <div class="text-center mb-2">
                  <v-btn @click="paymentOption = !paymentOption" v-if="!paymentOption" class="py-1 px-2" elevation="0"
                    color="white">
                    <v-icon left small>mdi-swap-horizontal</v-icon>
                    <span class="text-capitalize" style="font-size: 13px!important;font-weight: 700">Change Payment
                      Method</span>
                  </v-btn>
                </div>
                <div class="text-center">
                  <v-btn elevation="0" class="py-1 px-2" color="white" @click="cancelPayment">
                    <v-icon left small color="black">mdi-close</v-icon>
                    <span class="text-capitalize" style="font-size: 13px!important;font-weight: 700">Cancel Payment</span>
                  </v-btn>
                </div>

              </div>
            </div>

            <p class="text-center mt-3 d-none ">
              <span class="topic1" style="font-size: 14px!important;">Need a payment link like this? </span>
              <a target="_blank" class="topic1" style="color:#AB005E!important; font-size: 14px!important;
                   text-decoration: underline!important;"
                :href="'https://businessportal.1app.online/register-start'">Register here</a>
            </p>

            <component is="script" src="https://checkout.squadco.com/widget/squad.min.js" async></component>
            <component is="script" v-if="getCheckOutDetails.islive === 'test'"
              src="https://webpay-ui.k8.isw.la/inline-checkout.js"></component>
            <component is="script" v-else src="https://newwebpay.interswitchng.com/inline-checkout.js"></component>

          </v-col>
        </v-row>
      </v-img>

    </v-main>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import copyText from "@/hooks/copyText";
import formatAmount from "@/hooks/formatAmount";

export default {
  name: "checkOut",
  data() {
    return {
      currentPayment: 0,
      rules: {
        required: value => !!value || 'Required',
        length: value => value.length >= 3 || 'Enter  valid detail',
        emailFormat: value => {
          const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
          return pattern.test(value) || 'Invalid e-mail.'
        },
        passwordFormat: value => {
          const pattern = /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/
          return pattern.test(value) || "Invalid Password Format"
        },


      },
      contents: [1, 2, 3, 4],
      loader: false,
      snackbar: false,
      message: '',
      color: '',
      option: '1app',
      oneApp: false,
      email: '',
      password: '',
      visible: false,
      loginLoader: false,
      loginStatus: false,
      otp: '',
      transferMode: false,
      initializeLoader: false,
      resendLoader: false,
      paymentOption: false,
      transferLoader: false,
      userinfo: '',
      amountToPay: '',
      item: '',
      publicKey: '',
      merchantID: '',
      amountToPayKobo: '',
      pageLoader: true,

    }
  },
  computed: {
    ...mapGetters([
      'getCheckOutDetails',
      'getIntializeTransfer',
      'getTransferTime',
      'getPaymentProfile',

    ]),
    timeCalculator() {
      //returns the user time in a formatted form
      if (this.getTransferTime <= 0) return ['00', '00', '00']
      let hour = Math.floor(this.getTransferTime / 3600);
      let min = Math.floor((this.getTransferTime % 3600) / 60);
      let sec = this.getTransferTime % 3600 % 60;
      return [
        this.setTime(hour),
        this.setTime(min),
        this.setTime(sec)
      ]
    },
    gateway() {
      //filter out the available gateways
      if (this.getCheckOutDetails === '') return []
      let data = this.getCheckOutDetails.gateways || []
      let filter = data.filter(item => item.dstatus == 1)
      let result = [], count = 0
      filter.forEach(item => {
        result.push({ key: count, ...item })
        count += 1
      })

      return result


    },
    quicktellerURL() {
      //returns quickteller url based on account mode
      if (this.getCheckOutDetails?.islive === 'test') return `https://webpay-ui.k8.isw.la/inline-checkout.js`
      return `https://newwebpay.interswitchng.com/inline-checkout.js`
    }
  },
  methods: {
    async initializePayment() {
      //console.log(this.getCheckOutDetails)      
      //console.log(this.getCheckOutDetails.redirecturl)
      //console.log(window.sessionStorage.getItem('onComplete'))
      try {

        //initiate transaction
        if (this.option !== 'transfer') this.loader = true //if payment is not transfer mode make the button loading

        //dispatch process transaction
        await this.$store.dispatch('processTransaction', {
          token: this.$route.params.access_token,
          reference: this.$route.params.reference,
          gateway: this.option,
          redirectlink: 'https://modaljs.oneappgo.com/verify-transaction',
        })
          .then(response => response.json())
          .then(async response => {
            this.loader = false
            //if transaction is not expired
            if (response.status && response.expired === false) {
              this.amountToPay = response.amount
              this.amountToPayKobo = response?.amountkobo
              this.publicKey = response?.mkey
              this.merchantID = response?.mid
              await sessionStorage.setItem('gateway', this.option)
              await sessionStorage.setItem('reference', this.$route.params.reference)
              await sessionStorage.setItem('token', this.$route.params.access_token)
              await sessionStorage.setItem('apiRoute', this.$route.query?.href)

              //if payment is neither transfer nor 1app mode redirect user to the payment page
              if (this.option !== 'transfer' && this.option !== '1app' && this.option !== 'squad' && this.option !== 'interswitch') {
                return window.location.href = response.redirect
              }

              return
            }

            //if response status is false
            this.loader = false
            this.snackbar = true
            this.message = response.message
            this.color = 'error'

            //if payment link has expired
            if (response.expired) {
              window.sessionStorage.clear()
              return setTimeout(() => this.cancelPayment(), 3000)
            }

          })
          .catch(e => console.log(e.message))

        //if payment mode is SquadCo
        if (this.option === 'squad') {
          const squadInstance = new squad({
            onClose: () => window.location.reload(),

            onLoad: () => console.log("Widget loaded successfully"),

            onSuccess: (res) => console.log(res),

            key: this.publicKey,


            email: this.$route.query.email,

            amount: this.amountToPayKobo, //amount in kobo coming from API

            callback_url: 'https://modaljs.oneappgo.com/verify-transaction',
            // callback_url: 'https://modaljs.oneappgo.com/verify-transaction',

            merchant_id: this.merchantID,

            transaction_ref: this.$route.params.reference,

            Customer_name: `${this.$route.query.lname} ${this.$route.query.fname}`,

            payment_channel: ['CARD'],

            merchant_logo: `https://imagedelivery.net/IJzyw_ukSF-3OTlkcw9dIw/3a34c658-aecd-4376-0610-e30075f71000/public`,

            //Enter amount in Naira or Dollar (Base value Kobo/cent already multiplied by 100)

            currency_code: "NGN"

          });
          squadInstance.setup();
          squadInstance.open();
          return

        }

        //if payment mode is interswitch
        if (this.option === 'interswitch') {
          const samplePaymentRequest = {
            merchant_code: this.publicKey,
            pay_item_id: this.merchantID,
            pay_item_name: `Payment to ${this.getCheckOutDetails.bname}`,
            cust_name: `${this.$route.query.fname} ${this.$route.query.lname}`,
            cust_mobile_no: `${this.$route.query.phone}`,
            site_redirect_url: 'https://modaljs.oneappgo.com/verify-transaction',
            txn_ref: `${this.getCheckOutDetails.reference}`,
            amount: this.amountToPayKobo,
            currency: 566, // ISO 4217 numeric code of the currency used
            onComplete: (response) => {
              // console.log(response)
              if (response.resp == '00') {
                this.$router.push({
                  name: 'transactionVerifier',
                  query: {
                    reference: response.txnref
                  }


                })
              }
            },
            mode: `${(this.getCheckOutDetails?.islive).toUpperCase()}`
          }
          window.webpayCheckout(samplePaymentRequest);
          return
        }




      } catch (e) {
        window.alert(e.message)

      }


    },
    async loginUser() {

      //login user using 1app
      if (this.$refs['loginUser'][0].validate()) {

        this.loginLoader = true
        await this.initializePayment()
        await this.$store.dispatch('verifyUserLogin', {
          ref: this.$route.params.reference,
          token: this.$route.params.access_token,
          email: this.email,
          pwd: this.password,
        })
          .then(response => response.json())
          .then(response => {
            this.snackbar = true
            this.message = response.message
            this.loginLoader = false
            if (response.status && response.authorized === '01') {
              sessionStorage.setItem('appuserid', response.dhisid)
              this.loginStatus = true
              this.userinfo = response.userinfo
              return this.color = 'success'
            }
            this.color = 'error'

          })
      }

    },
    async verifyOtp() {
      //verify user entered otp to complete transaction
      if (this.$refs['otp'][0].validate()) {
        this.loginLoader = true
        await this.$store.dispatch('verifyOTP', {
          ref: this.$route.params.reference,
          token: this.$route.params.access_token,
          otp: this.otp,
          appuserid: sessionStorage.getItem('appuserid'),
        }).then(response => response.json())
          .then(async response => {
            if (response.status && response.authorized === '01') {

              if (response.redirecturl) return window.parent.location.href = response?.redirecturl
              if (window.sessionStorage.onComplete == 'true') {
                window.parent.postMessage({
                  channel: "1app",
                  gateway: "1app",
                  message: "Transaction completed",
                  paytru: "api",
                  redirecturl: "",
                  status: true,
                  token: this.$route.params.access_token,
                  reference: this.$route.params.reference,
                }, this.$route.query?.href || '*')
                return
              }
              await this.$store.commit('updateVerifiedTransaction', {
                status: true,
                message: 'Transaction completed',
                token: this.$route.params.access_token,
                reference: this.$route.params.reference,

              })
              await this.$router.push({ name: 'confirmTransaction' })
              return
            }
            this.loginLoader = false
            this.snackbar = true
            this.message = response.message
            this.color = 'error'
          })
          .catch(e => console.log(e.message))
      }
    },
    formatCurrency: amount => formatAmount(amount),
    setTime(value) {
      if (value <= 9) {
        let newTime = String(value);
        return newTime.padStart(2, 0)
      }
      return (value);

    },
    async verifyTransfer() {
      //veify transfer page
      this.loader = true
      await this.$store.dispatch('checkDynamicTransfer', {
        token: this.$route.params.access_token,
        reference: this.$route.params.reference,
        amount: this.getIntializeTransfer.amount,
        accntno: this.getIntializeTransfer.accountno
      })
        .then(response => response.json())
        .then(async response => {
          this.loader = false
          this.snackbar = true
          this.message = response.message
          if (response.status && response.authorized === '01') {
            this.color = 'success'

            setTimeout(async () => {
              //if redirect url was passed, redirect to url
              if (response.redirecturl) return window.parent.location.href = response?.redirecturl

              //else if emit event with the response
              if (window.sessionStorage.onComplete == 'true') {
                window.parent.postMessage({
                  channel: "transfer",
                  gateway: "transfer",
                  message: "Transaction completed",
                  paytru: "api",
                  redirecturl: "",
                  status: true,
                  token: this.$route.params.access_token,
                  reference: this.$route.params.reference,
                }, this.$route.query?.href || '*')
                return
              }


              //else update transaction details
              await this.$store.commit('updateVerifiedTransaction', {
                status: true,
                message: 'Transaction completed',
                token: this.$route.params.access_token,
                reference: this.$route.params.reference,

              })

              //redirect to completed transaction page
              await this.$router.push({ name: 'confirmTransaction' })

              return true
            }, 2000)

            return false
          }


          this.color = 'error'
        }).catch(e => console.log(e.message))
    },
    async initializeTransfer() {
      this.initializeLoader = true
      await this.$store.dispatch('intializeTransfer', {
        token: this.$route.params.access_token,
        reference: this.$route.params.reference,
        gateway: 'transfer',
      })
      this.initializeLoader = false
    },
    async modeChanger(item) {
      this.option = item.value
      this.currentPayment = item.key
      if (item.value === 'transfer') {
        await this.initializePayment()
        await this.initializeTransfer()


      }
    },
    copy: text => copyText(text),
    async cancelPayment() {
      await this.$store.commit('updateInitializeTransfer', '')
      await this.$store.commit('updateTransferTimer', '')
      await this.$store.commit('updateCheckOutDetails', '')
      window.parent.location.href = this.$route.query?.href
    },
    tester(event) {
      let find = this.gateway.find(item => item.key === event)
      return this.option = find?.value
    }
  },

  async mounted() {


    sessionStorage.setItem('onComplete', this.$route.query.onCompletion)
    sessionStorage.setItem('location', this.$route.query.href)

    await this.$store.dispatch('fetchCheckOutDetails', {
      token: this.$route.params.access_token,
      reference: this.$route.params.reference,
      href: this.$route.query?.href

    })
    window.document.title = `${this.getCheckOutDetails.bname} - check out page `

    const gateway = this.gateway.find((element) => element.dstatus == 1)
    if (gateway) {
      this.modeChanger(gateway)
    }
    setTimeout(() => this.pageLoader = false, 3000)
  },
  async beforeDestroy() {
    //reset cached data before closing checkout page
    await this.$store.commit('updateInitializeTransfer', '')
    await this.$store.commit('updateTransferTimer', '')
  }
}
</script>

<style scoped>
html,
body {
  background: #E5E5E5 !important;
}

.cards {
  box-shadow: 0 20px 27px 0 rgb(0 0 0 / 5%) !important;
  border: 0 solid rgba(0, 0, 0, .125) !important;
  border-radius: 1rem !important;
}

.sectionHeaders {
  font-size: 1.1rem;
  line-height: 1.0 !important;
  color: #344767;
  font-weight: 600;
  font-family: Inter, sans-serif !important;

}

.NqanGvcWiCU6BArN5gOD {
  position: absolute;
  top: -5px;
  right: -5px;
  z-index: 1;
  width: 105px;
  height: 105px;
  overflow: hidden;
  text-decoration: none;
  transition: all 0.1s ease-in;
  pointer-events: none;
  will-change: transform;
}

element.style {}

.PXlarHSsuCPnwMqPtjzN {
  position: relative;
  top: 21px;
  left: -11px;
  z-index: 1;
  display: block;
  width: 160px;
  padding: 9px 0 6px 0;
  color: #ffffff;
  font-weight: bold;
  line-height: 1.2;
  text-align: center;
  text-decoration: none;
  background-color: #ab005e;
  box-shadow: 0 2px 4px 0 rgb(0 0 0 / 20%);
  transform: rotate(45deg);
  transition: all 0.1s ease-in;
  pointer-events: auto;
}

.HfwXc2kW0vSgTdBa0sD6 {
  margin-left: -7px;
  color: #F2F2F2;
  font-size: 8px;
  text-transform: uppercase;
}

.sv3atfFjLzu9yiAmaUZk {
  margin-left: -7px;
  color: #ffffff;
  font-size: 14px;
}

.NqanGvcWiCU6BArN5gOD.pel451_UGSUBPu_cv4YV::after {
  position: absolute;
  width: 6px;
  height: 6px;
  background-color: #505960;
  transition: all 0.1s ease-in;
  content: '';
}

.NqanGvcWiCU6BArN5gOD.pel451_UGSUBPu_cv4YV::after {
  right: 0;
  bottom: 0;
}

.NqanGvcWiCU6BArN5gOD.pel451_UGSUBPu_cv4YV::before,
.NqanGvcWiCU6BArN5gOD.pel451_UGSUBPu_cv4YV::after {
  position: absolute;
  width: 6px;
  height: 6px;
  background-color: #505960;
  transition: all 0.1s ease-in;
  content: '';
}

.NqanGvcWiCU6BArN5gOD.pel451_UGSUBPu_cv4YV::before {
  top: 0;
  left: 0;
}

.topic1 {
  line-height: 1.625 !important;
  font-weight: 500 !important;
  font-size: 12px !important;
  margin-top: 0 !important;
  margin-bottom: 1rem !important;
  color: #444 !important;
  font-family: Inter, sans-serif !important;
}

label {
  line-height: 1.625;
  font-weight: 400;
  font-size: 13px;
  color: #67748e;
  text-align: center !important;
  font-family: Inter, sans-serif !important;
}

.payButton {
  background: #AB005E !important;
  border-color: #AB005E !important;
  color: #fff !important;

  padding: 0.6rem 1rem !important;
  font-size: 13px !important;
  opacity: 1 !important;
  border-radius: 3px !important;
  font-weight: 400 !important;
}

label {
  line-height: 1.625;
  font-weight: 400;
  font-size: 13px;
  color: #2f3d4d;
  text-align: center !important;
  font-family: Inter, sans-serif !important;
}

.form-holder {
  background-color: white;
  border-radius: 16px;
  box-shadow: rgb(145 158 171 / 24%) -24px 24px 72px -8px;
  line-height: 1.6 !important;
}

.active-tab {
  color: #AB005E !important;
  font-weight: 600 !important;


}

.tab-headers {
  display: block;
  font-size: 14px;
  font-weight: 500;
  line-height: 1.4;
  text-align: center;

  color: #2f3d4d;
}

.close {
  position: absolute;
  top: 100px;
  right: -20px;
  height: 16px;
  width: 16px;
  padding: 0;
  display: inline-block;
  z-index: 3;
  border-radius: 50%;
  background: transparent;
  -webkit-transition: all .3s;
  transition: all .3s;
  outline: none;
  cursor: pointer;
  margin-bottom: 10px;
  border: none;
}
</style>